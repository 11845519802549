import { type ZodIssue } from "@pollen/core/client/utils/zod.utils";

export type BatchRegistrationValidatorEntry = {
  lineIndex: number;
  input: Record<string, string> & { sessions: number[] };
  errors: ZodIssue[];
};

export type BatchRegistrationValidatorEntryWithSessionContext =
  BatchRegistrationValidatorEntry & {
    alreadyRegistered?: boolean;
  };

export async function useSessionBatchRegistrationValidator({
  organization_id,
  sessions,
}: {
  organization_id: DatabaseTable<"organizations">["id"];
  sessions?: DatabaseTable<"course_sessions">["id"][];
}) {
  const { t } = useI18n();

  const RegistrationLineSchema = z.object({
    first_name: z
      .string({
        required_error: t("app.form.errors.required", { field: "first_name" }),
      })
      .min(1, t("app.form.errors.required", { field: "first_name" })),
    last_name: z
      .string({
        required_error: t("app.form.errors.required", { field: "last_name" }),
      })
      .min(1, t("app.form.errors.required", { field: "last_name" })),
    job_title: z
      .string()
      .min(1, t("app.form.errors.required", { field: "job_title" })),
    email: z
      .string({
        required_error: t("app.form.errors.required", { field: "email" }),
      })
      .email(t("app.form.errors.invalid_email", { field: "email" })),
    sessions: z.string().optional(),
  });

  const { data: availableSessions } = await useFetch(
    `/api/organizations/${organization_id}/sessions`,
    {
      query: {
        time_window: "UPCOMING",
        sold_out: false,
        self_serve_registration: true,
        attendees_list_limit: "none",
        sessions,
      },
      deep: false,
    },
  );

  const lines = ref<{ [p: string]: string }[] | undefined>();
  const errors = ref<ZodIssue[]>([]);

  const entries = computed(() => {
    return (
      lines.value?.map((line, index) => {
        const entry: BatchRegistrationValidatorEntry = {
          // @ts-expect-error messing with mixed record field types
          input: {
            ...line,
            sessions:
              sessions ?? line["sessions"]?.split(" ").map(Number) ?? [],
          },
          errors: errors.value.filter((error) => error.path[0] === index),
          /*
            Increment of 2 here to have line index in CSV file :
            - 1 for the 1 based indxed line counts
            - 1 for the headers line
             */
          lineIndex: index + 2,
        };

        return entry;
      }) ?? []
    );
  });

  const entriesPerAvailableSession = computed(() => {
    return (
      availableSessions.value
        ?.map((session) => {
          const sessionEntries = entries.value
            .filter((entry) => entry.input.sessions.includes(session.id))
            .reduce((uniqEntries, entry) => {
              const entryEmail = entry.input["email"];
              if (uniqEntries.every((e) => e.input["email"] !== entryEmail)) {
                const alreadyRegistered = session.organization_attendees.some(
                  (a) => a.user.email === entryEmail,
                );

                uniqEntries.push({
                  ...entry,
                  errors: alreadyRegistered ? [] : entry.errors,
                  alreadyRegistered,
                });
              }

              return uniqEntries;
            }, [] as BatchRegistrationValidatorEntryWithSessionContext[]);

          const effectiveUniqRegistrationsCount = sessionEntries.filter(
            (e) => !e.alreadyRegistered,
          ).length;

          const hasExceededRemainingSeatsLimit =
            session.remaining_seats! < effectiveUniqRegistrationsCount;

          const hasErrors =
            hasExceededRemainingSeatsLimit ||
            sessionEntries.some((entry) => entry.errors.length > 0);

          return {
            session,
            entries: sessionEntries,
            hasErrors,
            hasExceededRemainingSeatsLimit,
            effectiveUniqRegistrationsCount,
          };
        })
        .filter((item) => item.entries.length > 0) ?? []
    );
  });

  const entriesWithoutRelatedSession = computed(() => {
    return entries.value.filter((entry) => {
      return entriesPerAvailableSession.value.every((item) =>
        item.entries.every((e) => e.input["email"] !== entry.input["email"]),
      );
    });
  });

  watch(
    lines,
    (value) => {
      const result = z.array(RegistrationLineSchema).safeParse(value);

      if (result.success) {
      } else {
        errors.value = result.error.errors;
      }
    },
    { deep: false },
  );

  const hasLines = computed(() => !!lines.value?.length);

  const hasErrors = computed(() =>
    entriesPerAvailableSession.value.some((item) => item.hasErrors),
  );

  const validRegistrationsCount = computed(() =>
    entriesPerAvailableSession.value.reduce(
      (total, item) =>
        total +
        item.entries.filter(
          (e) => !e.alreadyRegistered && e.errors.length === 0,
        ).length,
      0,
    ),
  );

  function reset() {
    lines.value = undefined;
    errors.value = [];
  }

  return {
    availableSessions,
    entriesPerAvailableSession,
    lines,
    hasErrors,
    hasLines,
    validRegistrationsCount,
    reset,
    entriesWithoutRelatedSession,
  };
}
